export const leadSources = [
    { key: "fb-leads", value: "Facebook Leads" },
    { key: "web", value: "Web" },
    { key: "auto_created", value: "Direct WA" },
    { key: "auto_created_slpv2", value: "Direct WA SLP V2" },
    { key: "input-agent", value: "Input Agent" },
    { key: "instagram-dm", value: "Instagram DM" },
    { key: "facebook-dm", value: "Facebook DM" },
    { key: "tiktok", value: "TikTok" },
    { key: "whatsapp", value: "Whatsapp" },
    { key: "call-wa", value: "Call Whatsapp" },
    { key: "call-gsm", value: "Call GSM" },
    { key: "auto_created_conversion", value: "Direct WA Conversion" },
]

export const leadMts = [
    { key: "facebook_ads", value: "Facebook Ads" },
    { key: "fb-leads", value: "Facebook Leads" },
    { key: "facebook-dm", value: "Facebook DM" },
    { key: "instagram", value: "Instagram" },
    { key: "whatsapp", value: "Whatsapp" },
    { key: "youtube", value: "Youtube" },
    { key: "google_ads", value: "Google Ads" },
    { key: "gdn", value: "GDN" },
    { key: "seo", value: "SEO" },
    { key: "others", value: "Others" },
    { key: "input-agent", value: "Input Agent" },
    { key: "direct", value: "Direct Web" },
    { key: "auto_created", value: "Direct WA" },
    { key: "auto_created_slpv2", value: "Direct WA SLP V2" },
    { key: "call", value: "Call" },
    { key: "tiktok", value: "Tiktok" },
    { key: "reference_agent", value: "Reference Agent" },
    { key: "reference_cp", value: "Reference CP" },
    { key: "auto_created_conversion", value: "Direct WA Conversion" },
]

export const inputAgentMts = [
    { key: "facebook_ads", value: "Facebook Ads" },
    { key: "facebook-dm", value: "Facebook DM" },
    { key: "instagram", value: "Instagram" },
    { key: "whatsapp", value: "Whatsapp" },
    { key: "google_ads", value: "Google Ads" },
    { key: "gdn", value: "GDN" },
    { key: "seo", value: "SEO" },
    { key: "others", value: "Others" },
    { key: "input-agent", value: "Input Agent" },
    { key: "direct", value: "Direct Web" },
    { key: "call", value: "Call" },
    { key: "tiktok", value: "Tiktok" },
    { key: "reference_agent", value: "Reference Agent" },
    { key: "reference_cp", value: "Reference CP" },
]

export const inputAgentMtsWithUndefined = [...inputAgentMts, { key: "undefined", value: "Undefined" }]

export const agentRole = ['Sales Manager', 'Manager', 'Agent', 'Super Sales Manager', 'Telesales', 'Telesales Coordinator', 'Agent B2B', 'Pic Sales', 'Pic Managerial'];

export const agentOfficeId = 'kantor';